@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

* {
	font-family: 'Fira Code', monospace !important;
}

::selection {
	background: white;
	color: #111827;
}
